/*
 * @Author: guojiren 7755@123.com
 * @Date: 2023-01-02 11:38:47
 * @LastEditors: lishuguang 2275408726@qq.com
 * @LastEditTime: 2023-04-14 20:18:40
 * @FilePath: \xinghe-herbs-trace-admin-ui\src\permission.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import router from "./router";
import store from "./store";
import { Message } from "element-ui";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getAccessToken } from "@/utils/auth";
import { isRelogin } from "@/utils/request";
NProgress.configure({ showSpinner: false });

// 增加三方登陆 update by 芋艿
const whiteList = [
  "/login",
  "/social-login",
  "/lyauth",
  "/auth-redirect",
  "/bind",
  "/register",
  "/oauthLogin/gitee",
];

router.beforeEach((to, from, next) => {
  // if (to.path == "/404") {
  //   localStorage.setItem('noPage','404')
  //   // alert("找不到页面/当前操作没有权限。。。");
  //   return;
  // }
  console.log(to, from, "是是是");
  NProgress.start();
  if (getAccessToken()) {
    to.meta.title && store.dispatch("settings/setTitle", to.meta.title);
    /* has token*/
    if (to.path === "/login") {
      next({ path: "/" });
      NProgress.done();
    } else {
      if (store.getters.roles.length === 0) {
        isRelogin.show = true;
        // 获取字典数据 add by 芋艿
        store.dispatch("dict/loadDictDatas");
        // 判断当前用户是否已拉取完user_info信息
        store
          .dispatch("GetInfo")
          .then(() => {
            isRelogin.show = false;
            store.dispatch("GenerateRoutes").then((accessRoutes) => {
              // 根据roles权限生成可访问的路由表
              router.addRoutes(accessRoutes); // 动态添加可访问路由表
              next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
            });
          })
          .catch((err) => {
            store.dispatch("LogOut").then(() => {
              Message.error(err);
              next({ path: "/" });
            });
          });
      } else {
        next();
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next(`/login?redirect=${to.fullPath}`); // 否则全部重定向到登录页
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
